<template>
  <TTabs
    :tabs="tabs"
    @change-tab="$router.push({ query: { tab: $event } })"
    :activeTab="$route.query.tab"
  >
    <template #supplier>
      <Supplier />
    </template>
  </TTabs>
</template>
<script>
import Supplier from "./components/Supplier.vue";
export default {
  components: { Supplier },
  data() {
    return {
      tabs: [
        { key: "supplier", name: "Supplier", icon: "cisStore" },
      ],
    };
  },
};
</script>
