<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap">
      <TMessage :content="supplier.id" class="my-auto" bold noTranslate />
      <span class="mx-1">-</span>
      <TMessage class="my-auto" :content="supplier.name" bold noTranslate />
      <TSpinner :loading="loading" />
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol xl="4" lg="6" md="6" sm="12" class="my-2">
          <TMessage content="Supplier" color="primary" bold>
            <template #prefix class="d-flex">
              <CIcon name="cilStore" class="mr-2" />
            </template>
          </TMessage>
          <TMessageText
            :value="supplier.name"
            class="mt-2"
            editable
            @change="
              $store.dispatch('product.purchase_suppliers.detail.update', {
                name: $event,
              })
            "
          />
        </CCol>
        <CCol xl="4" lg="6" md="6" sm="12" class="my-2">
          <TMessage content="Email" color="primary" bold>
            <template #prefix class="d-flex">
              <CIcon name="cilMail" class="mr-2" />
            </template>
          </TMessage>
          <TMessageText
            :value="supplier.email"
            class="mt-2"
            editable
            @change="
              $store.dispatch('product.purchase_suppliers.detail.update', {
                email: $event,
              })
            "
          />
        </CCol>
        <CCol xl="4" lg="6" md="6" sm="12" class="my-2">
          <TMessage content="Address" color="primary" bold>
            <template #prefix class="d-flex">
              <CIcon name="cilMap" class="mr-2" />
            </template>
          </TMessage>
          <TMessageText
            :value="supplier.address"
            class="mt-2"
            editable
            @change="
              $store.dispatch('product.purchase_suppliers.detail.update', {
                address: $event,
              })
            "
          />
        </CCol>
        <CCol xl="4" lg="6" md="6" sm="12" class="my-2">
          <TMessage content="Link" color="primary" bold>
            <template #prefix class="d-flex">
              <CIcon name="cilLink" class="mr-2" />
            </template>
          </TMessage>
          <TMessageText
            :value="supplier.link"
            class="mt-2"
            editable
            @change="
              $store.dispatch('product.purchase_suppliers.detail.update', {
                link: $event,
              })
            "
          />
        </CCol>
        <CCol xl="4" lg="6" md="6" sm="12" class="my-2">
          <TMessage content="Note" color="primary" bold>
            <template #prefix class="d-flex">
              <CIcon name="cilNote" class="mr-2" />
            </template>
          </TMessage>
          <TMessageText
            :value="supplier.note"
            class="mt-2"
            editable
            @change="
              $store.dispatch('product.purchase_suppliers.detail.update', {
                note: $event,
              })
            "
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    if (this.id) {
      this.$store.dispatch("product.purchase_suppliers.detail.fetch", this.id);
    }
  },
  computed: {
    ...mapGetters({
      supplier: "product.purchase_suppliers.detail",
      loading: "product.purchase_suppliers.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
